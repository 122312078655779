/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

:root {
    --size-m-box: 16px;
    --size-l-box: 24px;
}

.radio {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: var(--font);
    font-weight: var(--font-weight-normal);
    white-space: nowrap;

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__control {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        z-index: -1;
    }

    &__box {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-top: 0.05em;
        border-radius: 50%;
        transition: all 0.1s 0s ease-out;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            background: transparent;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            z-index: 2;
            opacity: 0;
            width: 100%;
            height: 100%;
            background: white;
            transition: all 0.1s 0s ease-out;
        }
    }

    &__text {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        line-height: normal;
    }

    &_size_m {
        font-size: var(--font-size-m);

        .radio__box {
            width: var(--size-m-box);
            height: var(--size-m-box);
            margin-right: var(--gap-xs);

            &:after {
                width: var(--size-m-box);
                height: var(--size-m-box);
            }
        }

        &.radio_checked .radio__box {
            &:before {
                width: 5px;
                height: 5px;
            }
        }
    }

    &_size_l {
        font-size: var(--font-size-xl);

        .radio__box {
            width: var(--size-l-box);
            height: var(--size-l-box);
            margin-right: var(--gap-s);

            &:after {
                width: var(--size-l-box);
                height: var(--size-l-box);
            }
        }

        &.radio_checked .radio__box {
            &:before {
                width: 8px;
                height: 8px;
            }
        }
    }

    &_disabled {
        cursor: default;
    }
}

.radio_type_button {
    display: inline-block;

    .radio__control {
        visibility: hidden;
        position: absolute;
    }

    /* TODO remove? */
    &.radio_checked {
        .button__text {
            font-weight: var(--font-weight-normal);
        }
    }
}

.radio_width_available {
    width: 100%;
}

/* TODO remove? */
.radio_invalid {
    .radio__box {
        border-color: var(--color-error-translucent);
    }
}

.radio_checked {
    .radio__box {
        transition: all 0.1s 0s ease-out;

        &:before {
            opacity: var(--opacity-active);
            transition: all 0.1s 0s ease-out;
        }
    }
}

.radio_focused {
    .radio__box {
        &:after {
            border: 2px solid;
        }
    }
}
