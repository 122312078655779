/* hash: 1p19z */
:root {
    --color-dark-bg-primary-inverted: #fff;
    --color-dark-border-key: #fff;
    --color-dark-border-primary: #485767;
    --color-dark-border-underline: #6d7986;
    --color-dark-text-primary: #fff;
    --color-dark-text-primary-inverted: #0b1f35;
    --color-dark-text-tertiary: rgba(255, 255, 255, 0.4);
    --color-dark-bg-primary-inverted-alpha-7: rgba(255, 255, 255, 0.07);
    --color-dark-bg-primary-inverted-shade-7: rgb(237, 237, 237);
    --color-dark-bg-primary-inverted-shade-15: rgb(217, 217, 217);
}
:root {

    /* Hard */

    /* Up */

    /* Hard up */
}
:root {
    /* border */

    /* background-color */

    /* text color */

    /* inverted */

    /* border */
    --tag-inverted-border-color: var(--color-dark-border-underline);
    --tag-inverted-border-color-hover: var(--color-dark-border-key);
    --tag-inverted-border-color-active: var(--color-dark-border-key);
    --tag-inverted-border-color-checked: var(--color-dark-border-key);
    --tag-inverted-border-color-disabled: var(--color-dark-border-primary);
    --tag-inverted-border-color-checked-disabled: transparent;

    /* background-color */
    --tag-inverted-background-color: transparent;
    --tag-inverted-background-color-hover: transparent;
    --tag-inverted-background-color-disabled: transparent;
    --tag-inverted-background-color-active: var(--color-dark-bg-primary-inverted-alpha-7);
    --tag-inverted-background-color-checked: var(--color-dark-bg-primary-inverted);
    --tag-inverted-background-color-checked-hover: var(--color-dark-bg-primary-inverted-shade-7);
    --tag-inverted-background-color-checked-active: var(--color-dark-bg-primary-inverted-shade-15);
    --tag-inverted-background-color-checked-disabled: var(
        --color-dark-bg-primary-inverted-alpha-30
    );

    /* text color */
    --tag-inverted-text-color: var(--color-dark-text-primary);
    --tag-inverted-text-color-checked: var(--color-dark-text-primary-inverted);
    --tag-inverted-text-color-disabled: var(--color-dark-text-tertiary);
    --tag-inverted-text-color-checked-disabled: var(--color-dark-text-primary-inverted);
}
.tag__component_kbpcm {
    color: var(--tag-inverted-text-color);
    border-color: var(--tag-inverted-border-color);
    background-color: var(--tag-inverted-background-color)
}
.tag__component_kbpcm:disabled {
        border-color: var(--tag-inverted-border-color-disabled);
        color: var(--tag-inverted-text-color-disabled);
        background-color: var(--tag-inverted-background-color-disabled);
    }
.tag__component_kbpcm:hover:not(:disabled) {
        border-color: var(--tag-inverted-border-color-hover);
        background-color: var(--tag-inverted-background-color-hover);
    }
.tag__component_kbpcm:active:not(:disabled) {
        border-color: var(--tag-inverted-border-color-active);
        background-color: var(--tag-inverted-background-color-active);
        color: var(--tag-inverted-text-color);
    }
.tag__checked_kbpcm {
    border-color: var(--tag-inverted-border-color-checked);
    background-color: var(--tag-inverted-background-color-checked);
    color: var(--tag-inverted-text-color-checked)
}
.tag__checked_kbpcm:disabled {
        background-color: var(--tag-inverted-background-color-checked-disabled);
        border-color: var(--tag-inverted-border-color-checked-disabled);
        color: var(--tag-inverted-text-color-checked-disabled);
    }
.tag__checked_kbpcm:hover:not(:disabled):not(:active) {
        background-color: var(--tag-inverted-background-color-checked-hover);
    }
.tag__checked_kbpcm:active:not(:disabled) {
        background-color: var(--tag-inverted-background-color-checked-active);
        color: var(--tag-inverted-text-color-checked);
    }
