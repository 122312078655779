/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.attach {
    display: inline-flex;
    align-items: center;
    font-family: var(--font);
    font-weight: var(--font-weight-normal);

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__label,
    &__control {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__label {
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__control {
        opacity: 0;
        cursor: pointer;

        /*
         * Хак для обхода поведения IE10+, где левая половина контрола — «поле ввода»,
         * в которое можно поставить курсор. Нажатие в такое поле не вызывает click,
         * а только ставит курсор, который виден независимо от видимости контрола.
         * Cкейлинг размещает в видимом пространстве кнопки именно кнопку инпута.
         */
        width: 100px;
        height: 50px;
        transform: scale(20);
    }

    &_size_s &__file,
    &_size_s &__no-file {
        font-size: var(--font-size-s);
    }

    &_size_m &__file,
    &_size_m &__no-file {
        font-size: var(--font-size-m);
    }

    &_size_l &__file,
    &_size_l &__no-file {
        font-size: var(--font-size-l);
    }

    &_size_xl &__file,
    &_size_xl &__no-file {
        font-size: var(--font-size-xl);
    }

    &__file,
    &__no-file {
        display: inline;
        margin-left: 0.5em;
        text-align: center;
    }

    &__clear {
        margin: 0 0 0 5px;
        padding: 0;
        cursor: pointer;
        outline: none;
        user-select: none;
        color: inherit;
        font: inherit;
        background: none;
        border: none;

        &:before {
            content: "×";
        }
    }

    &__progress-bar {
        margin-top: var(--gap-2xs);
    }

    &_disabled &__control {
        cursor: default;
    }

    &_disabled &__no-file,
    &_disabled &__file {
        opacity: var(--opacity-disabled);
    }

    &_disabled &__clear {
        display: none;
    }
}
