/* hash: 1cz7m */
:root {
    --color-light-text-primary: #0b1f35;
}
:root {

    /* Hard */

    /* Up */

    /* Hard up */
}
:root {
    --gap-xs: 8px;
}
.pagination__component_1yt5r {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    display: flex;
    align-items: center;
    color: var(--color-light-text-primary);
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
}
.pagination__defaultView_1yt5r > * {
        margin-right: var(--gap-xs)
    }
.pagination__defaultView_1yt5r > *:last-child {
            margin-right: 0;
        }
