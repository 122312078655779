/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.menu_theme_alfa-on-color {
    .menu-item {
        color: var(--color-content-alfa-on-color);
    }

    .menu-item_hovered:not(.menu-item_disabled) {
        background-color: var(--color-background-control-hovered-alfa-on-color);

        &.menu-item_type_link,
        &.menu-item_type_dropdown {
            background-color: transparent;
        }
    }

    .menu-item_disabled {
        color: var(--color-content-disabled-alfa-on-color);

        .icon {
            opacity: var(--opacity-disabled);
        }
    }

    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_checked:before {
            background-color: var(--color-content-alfa-on-color);
        }
    }

    &.menu_mode_check,
    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_disabled {
            &.menu-item_checked:before {
                opacity: var(--opacity-disabled);
            }
        }
    }

    .menu__group-title {
        color: var(--color-content-minor-alfa-on-color);
    }
}
