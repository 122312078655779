/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.radio_theme_alfa-on-color {
    color: var(--color-content-alfa-on-color);

    .radio__box {
        background: var(--color-background-control-alfa-on-color);
        border: 1px solid var(--color-border-control-alfa-on-color);
    }

    &.radio_checked {
        .radio__box {
            background: var(--color-accent-translucent);
        }

        &.radio_hovered .radio__box,
        &.radio_focused .radio__box {
            background: var(--color-accent);
        }
    }

    &.radio_focused {
        .radio__box {
            border-color: var(--color-border-control-focused-alfa-on-color);

            &:after {
                border-color: var(--color-border-control-focused-alfa-on-color);
            }
        }
    }

    &.radio_hovered {
        .radio__box {
            border-color: var(--color-border-control-focused-alfa-on-color);
        }
    }

    &.radio_disabled {
        color: var(--color-content-disabled-alfa-on-color);

        .radio__box {
            background: var(--color-background-control-disabled-alfa-on-color);
            border-color: transparent;
        }
    }
}
