/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.label {
    font-family: var(--font);
    font-weight: var(--font-weight-normal);

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__inner {
        display: inline-block;
        max-width: 100%;
    }

    &_no-wrap &__inner {
        display: inline;
        white-space: nowrap;
    }

    &_size_s {
        font-size: var(--font-size-s);
        line-height: var(--line-height-normal);
    }

    &_size_m {
        font-size: var(--font-size-m);
        line-height: var(--line-height-normal);
    }

    &_size_l {
        font-size: var(--font-size-l);
        line-height: var(--line-height-semi-condensed);
    }

    &_size_xl {
        font-size: var(--font-size-xl);
        line-height: var(--line-height-condensed);
    }

    &_size_2xl {
        font-size: var(--font-size-2xl);
        line-height: var(--line-height-condensed);
    }

    &_size_3xl {
        font-size: var(--font-size-3xl);
        line-height: var(--line-height-condensed);
    }

    &_size_4xl {
        font-size: var(--font-size-4xl);
        line-height: var(--line-height-extra-condensed);
    }
}

.label_theme_alfa-on-color {
    color: var(--color-content-alfa-on-color);

    &.label_disabled {
        color: var(--color-content-disabled-alfa-on-color);
    }
}

.label_theme_alfa-on-white {
    color: var(--color-content-alfa-on-white);

    &.label_disabled {
        color: var(--color-content-disabled-alfa-on-white);
    }
}
