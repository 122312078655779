/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.input_theme_alfa-on-white {
    .input__box {
        border-bottom-color: var(--color-border-control-alfa-on-white);
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }

    .input__control {
        color: var(--color-content-alfa-on-white);

        &::placeholder {
            color: var(--color-content-minor-alfa-on-white);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-text-fill-color: var(--color-content-alfa-on-white);
            caret-color: var(--color-content-alfa-on-white);
        }
    }

    .input__top,
    .input__sub {
        color: var(--color-content-minor-alfa-on-white);
    }

    &.input_has-label {
        .input__control::placeholder {
            color: transparent;
        }

        &.input_focused {
            .input__control::placeholder {
                color: var(--color-content-minor-alfa-on-white);
            }
        }
    }

    &.input_has-icon {
        .input__icon {
            color: var(--color-content-alfa-on-white);
        }
    }

    &.input_focused {
        .input__box {
            border-bottom-color: var(
                --color-border-control-focused-alfa-on-white
            );
            box-shadow: inset 0 -1px 0 var(--color-border-control-focused-alfa-on-white);
        }
    }

    &.input_disabled {
        .input__box {
            border-bottom-color: var(
                --color-border-control-disabled-alfa-on-white
            );
            border-bottom-style: var(--border-style-control-disabled);
            box-shadow: none;
        }

        &,
        .input__control {
            color: var(--color-content-disabled-alfa-on-white);

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-text-fill-color: var(
                    --color-content-disabled-alfa-on-white
                );
            }
        }

        .input__control::placeholder {
            color: var(--color-content-disabled-alfa-on-white);
        }

        .input__icon {
            opacity: var(--opacity-disabled);
        }
    }

    &.input_invalid {
        .input__sub {
            color: var(--color-error);
        }

        .input__box {
            border-bottom-color: var(--color-error-translucent);
        }

        &.input_focused {
            .input__box {
                border-bottom-color: var(--color-error-translucent);
                box-shadow: inset 0 -1px 0 var(--color-error-translucent);
            }
        }
    }
}
