/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.progress-bar {
    display: flex;
    width: 100%;
    min-width: 200px;
    height: 3px;
    overflow: hidden;

    &__current-value {
        background: var(--color-accent);
    }

    &_size_m {
        height: 3px;
    }
}
