/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
@import "../vars.css";

.tab-item {
    display: inline-block;
    cursor: pointer;
    outline: none;
    font-family: var(--font);
    text-decoration: none;
    transition: color 200ms ease-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);

    @media (--small-only) {
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        line-height: 0;
    }

    & + & {
        margin-left: 30px;

        @media (--small-only) {
            margin-left: 0;
        }
    }

    &_size_l {
        border-bottom-width: 3px;
        padding: var(--gap-s) 0;
        font-size: var(--font-size-l);
        line-height: var(--line-height-condensed);

        @media (--small-only) {
            padding: var(--gap-l) var(--gap-s);
            font-size: var(--font-size-xs);
        }
    }

    &_disabled,
    &_checked {
        cursor: default;
    }

    &_checked {
        position: relative;
        z-index: 2;
        border-bottom-style: solid;
    }

    &_disabled &__icon,
    &:not(&_checked) &__icon {
        opacity: var(--opacity-disabled);
    }
}

.tab-item_theme_alfa-on-color {
    color: var(--color-content-minor-alfa-on-color);

    &:hover:not(.tab-item_disabled),
    &:focus:not(.tab-item_disabled) {
        color: var(--color-content-alfa-on-color);
    }

    &.tab-item_checked {
        border-bottom-color: var(--color-accent);
        color: var(--color-content-alfa-on-color);

        @media (--small-only) {
            color: var(--color-content-accent-alfa-on-color);
        }
    }

    &.tab-item_disabled {
        color: var(--color-content-disabled-alfa-on-color);
    }
}

.tab-item_theme_alfa-on-white {
    color: var(--color-content-minor-alfa-on-white);

    &:hover:not(.tab-item_disabled),
    &:focus:not(.tab-item_disabled) {
        color: var(--color-content-alfa-on-white);
    }

    &.tab-item_checked {
        border-bottom-color: var(--color-accent);
        color: var(--color-content-alfa-on-white);

        @media (--small-only) {
            color: var(--color-content-accent-alfa-on-white);
        }
    }

    &.tab-item_disabled {
        color: var(--color-content-disabled-alfa-on-white);
    }
}
