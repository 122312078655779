/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.radio_theme_alfa-on-white {
    color: var(--color-content-alfa-on-white);

    .radio__box {
        background: var(--color-dark-indigo-05);
        border: 1px solid var(--color-dark-indigo-60);
    }

    &.radio_checked {
        .radio__box {
            background: rgba(239, 49, 36, 0.85);
            border-color: var(--color-dark-indigo-25-deprecated);
        }

        &.radio_hovered .radio__box,
        &.radio_focused .radio__box {
            background: var(--color-red-brand);
        }
    }

    &.radio_focused {
        .radio__box {
            background: var(--color-dark-indigo-10);

            &:after {
                border-color: rgba(11, 31, 53, 0.95);
            }
        }
    }

    &.radio_hovered {
        .radio__box {
            background: var(--color-dark-indigo-10);
        }
    }

    &.radio_disabled {
        color: var(--color-content-disabled-alfa-on-white);

        .radio__box {
            background: var(--color-dark-indigo-20-deprecated);
            border-color: transparent;
        }
    }

    &.radio:active {
        .radio__box {
            background: var(--color-dark-indigo-20-deprecated);
        }

        &.radio_checked {
            .radio__box,
            &.radio_hovered .radio__box,
            &.radio_focused .radio__box {
                background: var(--color-accent-alt);
            }
        }

        &.radio_disabled {
            .radio__box {
                background: var(--color-dark-indigo-20-deprecated);
            }
        }
    }
}
