/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.menu {
    outline: 0;
    user-select: none;
    font-family: var(--font);

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
}

.menu_view_horizontal {
    .menu-item {
        display: inline-block;
        vertical-align: top;

        &.menu-item_type_link:first-child {
            padding-left: 0;
        }

        &.menu-item_type_link:last-child {
            padding-right: 0;
        }
    }

    .menu-item:first-child {
        border-radius: var(--border-radius-control) 0 0
            var(--border-radius-control);
    }

    .menu-item:last-child {
        border-radius: 0 var(--border-radius-control)
            var(--border-radius-control) 0;
    }
}

.menu_mode_check {
    .menu-item_checked .icon {
        position: absolute;
    }
}

.menu_mode_radio,
.menu_mode_radio-check {
    .menu-item_checked:before {
        content: "";
        display: inline-block;
        position: absolute;
        border-radius: 50%;
    }
}

.menu_size_s {
    .menu__group-title {
        padding: 10px var(--gap-s);
        font-size: var(--font-size-s);
    }

    .menu-item {
        padding: 6px 10px;
        font-size: var(--font-size-s);
    }

    &.menu_mode_check {
        .menu-item_checked .icon {
            top: 7px;
            left: 4px;
        }
    }

    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_checked:before {
            top: calc(50% - 2px);
            width: 5px;
            height: 5px;
            margin-left: -14px;
        }
    }

    &.menu_mode_check,
    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item,
        .menu__group-title {
            padding-left: var(--gap-xl);
        }
    }

    .menu__group {
        .menu-item {
            padding-left: 36px;
        }
    }
}

.menu_size_m {
    .menu__group-title {
        padding: var(--gap-xs) var(--gap-s);
        font-size: var(--font-size-m);
    }

    .menu-item {
        padding: 9px var(--gap-s);
        font-size: var(--font-size-m);
    }

    &.menu_mode_check {
        .menu-item_checked .icon {
            top: 12px;
            left: 8px;
        }
    }

    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_checked:before {
            top: calc(50% - 3px);
            width: 6px;
            height: 6px;
            margin-left: -14px;
        }
    }

    &.menu_mode_check,
    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item,
        .menu__group-title {
            padding-left: 30px;
        }
    }

    .menu__group {
        .menu-item {
            padding-left: var(--gap-4xl);
        }
    }
}

.menu_size_l {
    .menu__group-title {
        padding: 10px 17px;
        font-size: var(--font-size-l);
    }

    .menu-item {
        padding: var(--gap-s) 15px 13px;
        font-size: var(--font-size-l);
    }

    &.menu_mode_check {
        .menu-item_checked .icon {
            top: 14px;
            left: 8px;
        }
    }

    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_checked:before {
            top: calc(50% - 4px);
            width: 8px;
            height: 8px;
            margin-left: -21px;
        }
    }

    &.menu_mode_check,
    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item,
        .menu__group-title {
            padding-left: 36px;
        }
    }

    .menu__group {
        .menu-item {
            padding-left: 56px;
        }
    }
}

.menu_size_xl {
    .menu__group-title {
        padding: var(--gap-s) 19px;
        font-size: var(--font-size-xl);
    }

    .menu-item {
        padding: 11px 15px;
        font-size: var(--font-size-xl);
    }

    &.menu_mode_check {
        .menu-item_checked .icon {
            top: 16px;
            left: 10px;
        }
    }

    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item_checked:before {
            top: calc(50% - 5px);
            width: 10px;
            height: 10px;
            margin-left: -22px;
        }
    }

    &.menu_mode_check,
    &.menu_mode_radio,
    &.menu_mode_radio-check {
        .menu-item,
        .menu__group-title {
            padding-left: var(--gap-3xl);
        }
    }

    .menu__group {
        .menu-item {
            padding-left: 50px;
        }
    }
}

.menu__group-title {
    font-weight: var(--font-weight-medium);
}

.menu__group {
    & + .menu__group {
        border-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
        border-top: 0;
    }
}

.menu_group-view_line {
    .menu__group {
        position: relative;
    }

    .menu__group-title {
        position: absolute;
        line-height: var(--line-height-normal);
    }

    &.menu_size_s {
        .menu__group-title {
            padding: 6px 3px;
        }
    }

    &.menu_size_m {
        .menu__group-title {
            padding: 9px var(--gap-s);
        }
    }

    &.menu_size_l {
        .menu__group-title {
            padding: var(--gap-s) 7px;
        }
    }

    &.menu_size_xl {
        .menu__group-title {
            padding: 11px 1px;
        }
    }
}
