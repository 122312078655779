@import "../vars.css";

.calendar_theme_alfa-on-white {
    color: var(--color-content-alfa-on-white);
    background-color: var(--color-background-alfa-on-white);

    .calendar {
        &__event {
            background-color: var(--color-background-alfa-on-white);

            &:after {
                background-color: var(--color-dark-indigo-30);
            }
        }

        &__wrapper {
            background-color: var(--color-background-alfa-on-white);

            &:before {
                background: linear-gradient(
                    to bottom,
                    var(--color-white) 0%,
                    var(--color-white) 33%,
                    rgba(255, 255, 255, 0) 100%
                );
            }
        }

        &__day {
            &:hover {
                background: var(--color-dark-indigo-10);
            }

            &_state_today {
                box-shadow: inset 0 0 0 1px var(--color-dark-indigo);
            }

            &_state_current {
                color: var(--color-white);
                background: var(--color-dark-indigo);

                &:hover {
                    background: var(--color-dark-indigo);
                }
            }
        }

        &__dayname {
            color: var(--color-dark-indigo-30);
        }

        &__day_empty,
        &__select_type_off,
        &__day_type_off,
        &__day_type_weekend-off {
            color: var(--color-dark-indigo-30);

            &:hover {
                background-color: transparent;
            }
        }

        &__name {
            &_month,
            &_year {
                background-color: var(--color-dark-indigo-05);

                &:hover {
                    background-color: var(--color-dark-indigo-15);
                }
            }
        }

        &__select {
            background: var(--color-dark-indigo-05);
            border: 1px solid var(--color-dark-indigo-15);

            &:hover {
                background: var(--color-dark-indigo-15);
                border-color: var(--color-dark-indigo-30);
            }

            &_type_off:hover {
                background: var(--color-dark-indigo-05);
                border-color: var(--color-dark-indigo-15);
            }

            &_state_current {
                border-color: var(--color-dark-indigo);

                &:hover {
                    border-color: var(--color-dark-indigo);
                }
            }
        }

        &__select-arrows {
            background-image: url(./images/calendar__select-arrows-white.svg);
        }

        &__arrow_direction_left {
            background-image: url(./images/calendar__arrow-left-white.svg);
        }

        &__arrow_direction_right {
            background-image: url(./images/calendar__arrow-right-white.svg);
        }
    }
}
