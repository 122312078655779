/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.checkbox_theme_alfa-on-color {
    color: var(--color-content-alfa-on-color);

    .checkbox__box {
        background: var(--color-background-control-alfa-on-color);
        border: 1px solid var(--color-border-control-alfa-on-color);
    }

    &.checkbox_checked {
        .checkbox__box {
            background: var(--color-accent-translucent);
        }

        &.checkbox_hovered .checkbox__box,
        &.checkbox_focused .checkbox__box {
            background: var(--color-accent);
        }
    }

    &.checkbox_focused {
        .checkbox__box {
            border-color: var(--color-border-control-focused-alfa-on-color);

            &:after {
                border-color: var(--color-border-control-focused-alfa-on-color);
            }
        }
    }

    &.checkbox_hovered {
        .checkbox__box {
            border-color: var(--color-border-control-focused-alfa-on-color);
        }
    }

    &.checkbox_disabled {
        color: var(--color-content-disabled-alfa-on-color);

        .checkbox__box {
            background: var(--color-background-control-disabled-alfa-on-color);
            border-color: transparent;
        }
    }
}
