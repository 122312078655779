/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.popup-header {
    position: relative;
    font-family: var(--font);

    &__title {
        font-weight: var(--font-weight-medium);
        line-height: var(--line-height-normal);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }

    &__closer {
        position: absolute;
        top: 0;
        left: 0;
    }

    &_size_s {
        padding: 6px var(--gap-4xl);
        font-size: var(--font-size-s);
    }

    &_size_m {
        padding: 9px var(--gap-4xl);
        font-size: var(--font-size-m);
    }

    &_size_l {
        padding: var(--gap-s) var(--gap-4xl);
        font-size: var(--font-size-l);
    }

    &_size_xl {
        padding: 11px var(--gap-4xl);
        font-size: var(--font-size-xl);
    }
}
