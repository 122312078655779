@import "../vars.css";

.heading {
    margin: 0;
    font-family: var(--font);
    font-weight: var(--font-weight-bold);

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &_size_xl {
        font-size: var(--font-size-2xl);
        line-height: var(--line-height-condensed);

        @media (--tablet-s) {
            font-size: var(--font-size-3xl);
        }

        @media (--desktop) {
            font-size: var(--font-size-4xl);
            line-height: var(--line-height-extra-condensed);
        }
    }

    &_margins_xl {
        margin: 0 0 var(--gap-xl);
    }

    &_size_l {
        font-size: var(--font-size-xl);
        line-height: var(--line-height-condensed);

        @media (--tablet-s) {
            font-size: var(--font-size-2xl);
        }

        @media (--desktop) {
            font-size: var(--font-size-3xl);
        }
    }

    &_margins_l {
        margin: var(--gap-3xl) 0 var(--gap-m);

        @media (--tablet-s) {
            margin: var(--gap-4xl) 0 var(--gap-xl);
        }
    }

    &_size_m {
        font-size: var(--font-size-l);
        line-height: var(--line-height-semi-condensed);

        @media (--tablet-s) {
            font-size: var(--font-size-xl);
            line-height: var(--line-height-condensed);
        }

        @media (--desktop) {
            font-size: var(--font-size-2xl);
        }
    }

    &_margins_m {
        margin: var(--gap-3xl) 0 var(--gap-m);

        @media (--desktop) {
            margin: var(--gap-4xl) 0 var(--gap-xl);
        }
    }

    &_size_s {
        font-size: var(--font-size-l);
        line-height: var(--line-height-semi-condensed);

        @media (--tablet-s) {
            font-size: var(--font-size-xl);
            line-height: var(--line-height-condensed);
        }
    }

    &_margins_s {
        margin: var(--gap-3xl) 0 var(--gap-m);
    }

    &_size_xs {
        font-size: var(--font-size-l);
        line-height: var(--line-height-semi-condensed);
    }

    &_margins_xs {
        margin: var(--gap-3xl) 0 var(--gap-m);
    }

    & + & {
        margin-top: 0;
    }

    &_theme_alfa-on-color {
        color: var(--color-content-alfa-on-color);
    }

    &_theme_alfa-on-white {
        color: var(--color-content-alfa-on-white);
    }
}
