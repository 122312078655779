/* hash: 7m0e6 */
:root {
    --color-light-bg-primary-inverted: #0b1f35;
    --color-light-border-key: #0b1f35;
    --color-light-border-primary: #dbdee1;
    --color-light-border-underline: #b6bcc3;
    --color-light-text-primary: #0b1f35;
    --color-light-text-primary-inverted: #fff;
    --color-light-text-tertiary: rgba(11, 31, 53, 0.3);
    --color-light-bg-primary-inverted-alpha-7: rgba(11, 31, 53, 0.07);
    --color-light-bg-primary-inverted-alpha-30: rgba(11, 31, 53, 0.3);
    --color-light-bg-primary-inverted-tint-15: rgb(48, 65, 83);
    --color-light-bg-primary-inverted-tint-20: rgb(60, 76, 93);
}
:root {

    /* Hard */

    /* Up */

    /* Hard up */
}
:root {
    /* border */
    --tag-border-color: var(--color-light-border-underline);
    --tag-border-color-hover: var(--color-light-border-key);
    --tag-border-color-active: var(--color-light-border-key);
    --tag-border-color-checked: var(--color-light-border-key);
    --tag-border-color-disabled: var(--color-light-border-primary);
    --tag-border-color-checked-disabled: transparent;

    /* background-color */
    --tag-background-color: transparent;
    --tag-background-color-hover: transparent;
    --tag-background-color-active: var(--color-light-bg-primary-inverted-alpha-7);
    --tag-background-color-disabled: transparent;
    --tag-background-color-checked: var(--color-light-bg-primary-inverted);
    --tag-background-color-checked-hover: var(--color-light-bg-primary-inverted-tint-15);
    --tag-background-color-checked-active: var(--color-light-bg-primary-inverted-tint-20);
    --tag-background-color-checked-disabled: var(--color-light-bg-primary-inverted-alpha-30);

    /* text color */
    --tag-text-color: var(--color-light-text-primary);
    --tag-text-color-checked: var(--color-light-text-primary-inverted);
    --tag-text-color-disabled: var(--color-light-text-tertiary);
    --tag-text-color-checked-disabled: var(--color-light-text-primary-inverted);

    /* inverted */

    /* border */

    /* background-color */

    /* text color */
}
.tag__component_x915z {
    color: var(--tag-text-color);
    border-color: var(--tag-border-color);
    background-color: var(--tag-background-color)
}
.tag__component_x915z:disabled {
        border-color: var(--tag-border-color-disabled);
        color: var(--tag-text-color-disabled);
        background-color: var(--tag-background-color-disabled);
    }
.tag__component_x915z:hover:not(:disabled) {
        border-color: var(--tag-border-color-hover);
        background-color: var(--tag-background-color-hover);
    }
.tag__component_x915z:active:not(:disabled) {
        border-color: var(--tag-border-color-active);
        background-color: var(--tag-background-color-active);
        color: var(--tag-text-color);
    }
.tag__checked_x915z {
    border-color: var(--tag-border-color-checked);
    background-color: var(--tag-background-color-checked);
    color: var(--tag-text-color-checked)
}
.tag__checked_x915z:disabled {
        background-color: var(--tag-background-color-checked-disabled);
        border-color: var(--tag-border-color-checked-disabled);
        color: var(--tag-text-color-checked-disabled);
    }
.tag__checked_x915z:hover:not(:disabled):not(:active) {
        background-color: var(--tag-background-color-checked-hover);
    }
.tag__checked_x915z:active:not(:disabled) {
        background-color: var(--tag-background-color-checked-active);
        color: var(--tag-text-color-checked);
    }
