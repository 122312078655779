/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.menu-item {
    position: relative;
    cursor: pointer;
    font-family: var(--font);
    line-height: var(--line-height-normal);
    transition: background 200ms ease;

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &_disabled {
        cursor: default;
    }

    &_checked {
        cursor: default;
    }

    &_hidden {
        display: none;
    }

    &_type_link {
        cursor: default;
    }

    &_type_link &__link {
        outline: none;
    }
}
