/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.calendar {
    position: relative;
    display: inline-block;
    width: 320px;
    min-height: 300px;
    padding: var(--gap-m) var(--gap-s);
    outline: 0;
    overflow: hidden;

    font-family: var(--font);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-normal);

    &_width_available {
        width: 100%;
    }

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__select-buttons {
        text-align: center;
    }

    &__name {
        transition: 0.2s;

        &_month,
        &_year {
            display: inline-block;
            vertical-align: top;
            padding: 0 var(--gap-xs) 0 var(--gap-s);

            height: 30px;

            cursor: pointer;
            outline: none;

            font-size: var(--font-size-l);
            line-height: var(--font-size-2xl);

            border-radius: var(--border-radius-control);
        }

        &_month {
            .calendar__select-text {
                min-width: 82px;
            }
        }

        &_year {
            margin-left: var(--gap-xs);

            .calendar__select-text {
                min-width: 42px;
            }
        }
    }

    &__wrapper {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        top: 46px;
        bottom: 0;
        overflow: hidden;

        &:before {
            display: block;
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            height: 20px;
            content: "";
        }
    }

    &__years,
    &__months {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: var(--gap-m) 0;
        text-align: center;
    }

    &__months {
        overflow: hidden;
    }

    &__years {
        overflow: auto;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__select {
        display: inline-block;
        vertical-align: top;

        margin: var(--gap-2xs);
        width: 90px;
        height: 48px;

        box-sizing: border-box;
        border-radius: var(--border-radius-control);
        outline: none;

        line-height: 46px;
        text-align: center;
        cursor: pointer;

        transition: 0.2s;
    }

    &__layout {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }

    &__title {
        position: relative;
        padding: 0 0 var(--gap-l);
        user-select: none;
    }

    &__layout {
        padding: 0;
        text-align: center;
    }

    &__dayname {
        cursor: default;
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-normal);
        line-height: var(--line-height-condensed);

        padding: 0 0 var(--gap-2xs);
    }

    &__day {
        width: 34px;
        height: 30px;
        margin: var(--gap-xs) auto 0;

        cursor: pointer;
        border-radius: var(--border-radius-control);
        line-height: var(--font-size-2xl);
        text-align: center;
        position: relative;
        outline: none;

        transition: 0.2s;
    }

    &__arrow {
        position: absolute;
        top: 0;

        width: 36px;
        height: 30px;
        line-height: var(--font-size-2xl);
        text-align: center;
        cursor: pointer;
        outline: none;

        font-size: var(--font-size-l);

        mix-blend-mode: normal;
        border-radius: var(--border-radius-control);

        background-position: center;
        background-repeat: no-repeat;

        &_direction_left {
            left: 0;
        }

        &_direction_right {
            right: 0;
        }
    }

    &__select-arrows {
        position: relative;
        float: right;

        width: 6px;
        height: 30px;
        margin-left: var(--gap-xs);

        background-position: center;
        background-repeat: no-repeat;
    }

    &__select-text {
        position: relative;
        float: left;
        text-align: left;
    }

    &__arrow:hover {
        opacity: var(--opacity-active);
    }

    &__day_type_weekend {
        /* font-weight: var(--font-weight-medium); */
    }

    &__event {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;

        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
    }

    &__day_state_today,
    &__day_state_current {
        position: relative;
        outline: 0;
        user-select: none;
        text-align: center;
        z-index: 2;
    }

    &__day_state_today {
        box-sizing: border-box;
        border-radius: var(--border-radius-control);
    }

    &__day_empty,
    &__select_type_off,
    &__day_type_off,
    &__day_type_weekend-off {
        cursor: default;
    }

    &__arrow_disabled {
        pointer-events: none;
        cursor: default;

        &,
        &:hover {
            opacity: 0;
        }
    }
}
