/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.select_theme_alfa-on-white {
    .select__top,
    .select__sub,
    .select__placeholder {
        color: var(--color-content-minor-alfa-on-white);
    }

    .select-button {
        color: var(--color-content-alfa-on-white);
        background: transparent;
        border-bottom-color: var(--color-border-control-alfa-on-white);
        border-bottom-style: solid;
        border-bottom-width: 1px;

        &_hovered {
            border-bottom-color: var(
                --color-border-control-hovered-alfa-on-white
            );
        }

        &:focus,
        &_focused {
            border-bottom-color: var(
                --color-border-control-focused-alfa-on-white
            );
            box-shadow: inset 0 -1px 0 var(--color-border-control-focused-alfa-on-white);

            &.select-button_hovered {
                border-bottom-color: var(
                    --color-border-control-focused-alfa-on-white
                );
                box-shadow: inset 0 -1px 0 var(--color-border-control-focused-alfa-on-white);
            }
        }

        &_pressed {
            border-bottom-color: var(
                --color-border-control-pressed-alfa-on-white
            );
        }

        &_disabled {
            color: var(--color-content-disabled-alfa-on-white);
            border-bottom-color: var(
                --color-border-control-disabled-alfa-on-white
            );
            border-bottom-style: var(--border-style-control-disabled);
        }
    }

    &.select_opened {
        .select-button {
            border-bottom-color: var(
                --color-border-control-focused-alfa-on-white
            );
            box-shadow: inset 0 -1px 0 var(--color-border-control-focused-alfa-on-white);
        }
    }

    &.select_invalid {
        .select__sub {
            color: var(--color-error);
        }

        .select-button {
            border-bottom-color: var(--color-error-translucent);

            &_hovered {
                border-bottom-color: var(--color-error-translucent);
            }

            &:focus,
            &_focused {
                border-bottom-color: var(--color-error-translucent);
                box-shadow: inset 0 -1px 0 var(--color-error-translucent);

                &.select-button_hovered {
                    border-bottom-color: var(--color-error-translucent);
                    box-shadow: inset 0 -1px 0 var(--color-error-translucent);
                }
            }
        }

        &.select_opened {
            .select-button {
                border-bottom-color: var(--color-error-translucent);
                box-shadow: inset 0 -1px 0 var(--color-error-translucent);
            }
        }
    }
}
