/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.textarea_theme_alfa-on-white {
    .textarea__control {
        color: var(--color-content-alfa-on-white);
        border-bottom: 1px solid var(--color-border-control-alfa-on-white);

        &::placeholder {
            color: var(--color-content-minor-alfa-on-white);
        }
    }

    .textarea__top,
    .textarea__sub {
        color: var(--color-content-minor-alfa-on-white);
    }

    &.textarea_focused {
        .textarea__control {
            border-bottom-color: var(
                --color-border-control-focused-alfa-on-white
            );
        }
    }

    &.textarea_focused {
        .textarea__control {
            box-shadow: inset 0 -1px 0 var(--color-border-control-focused-alfa-on-white);
        }
    }

    &.textarea_disabled {
        &,
        .textarea__control {
            color: var(--color-content-disabled-alfa-on-white);
        }

        .textarea__control {
            border-bottom-style: var(--border-style-control-disabled);

            &::placeholder {
                color: var(--color-content-disabled-alfa-on-white);
            }

            &:disabled {
                -webkit-text-fill-color: var(
                    --color-content-disabled-alfa-on-white
                ) !important;
            }
        }
    }

    &.textarea_invalid {
        .textarea__sub {
            color: var(--color-error);
        }

        .textarea__control {
            border-bottom-color: var(--color-error-translucent);
        }

        &.textarea_focused {
            .textarea__control {
                border-bottom-color: var(--color-error-translucent);
                box-shadow: inset 0 -1px 0 var(--color-error-translucent);
            }
        }
    }
}
