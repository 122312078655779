/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.input-group_theme_alfa-on-white {
    background: var(--color-background-control-alfa-on-white);
    border-radius: var(--border-radius-control);

    .input {
        background: transparent;
    }
}
