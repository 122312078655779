/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.calendar-input {
    display: inline-block;
    position: relative;

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &_width_available {
        width: 100%;
    }

    &__custom-control {
        .input__icon {
            cursor: pointer;
        }

        &.input_disabled .input__icon {
            cursor: default;
        }
    }

    &__native-control {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        min-width: 0;
        height: 100%;
        appearance: none;
        z-index: 1;
    }

    &__calendar-wrapper {
        .calendar {
            display: block;
        }
    }

    &__calendar-wrapper_mobile {
        display: block;
        width: 320px;
        margin: 0 auto;
    }
}

/* See #659 PR */

.calendar-input_theme_alfa-on-color {
    .calendar-input__custom-control {
        .input__control:disabled {
            -webkit-text-fill-color: var(--color-white-30) !important;
        }
    }
}

.calendar-input_theme_alfa-on-white {
    .calendar-input__custom-control {
        .input__control:disabled {
            -webkit-text-fill-color: var(--color-dark-indigo-30) !important;
        }
    }
}
