/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

:root {
    --popup-gradient: color(var(--color-background-alfa-on-color) a(0)) 0%,
        color(var(--color-background-alfa-on-color) a(0.4)) 40%,
        color(var(--color-background-alfa-on-color) a(0.9)) 75%,
        color(var(--color-background-alfa-on-color) a(1)) 100%;
}

.popup_theme_alfa-on-color {
    color: var(--color-content-alfa-on-color);
    box-shadow: var(--shadow-light);

    .popup__container {
        background-color: var(--color-background-alfa-on-color);
    }

    &.popup_height_adaptive .popup__gradient,
    &.popup_target_screen .popup__gradient {
        &_top {
            background: linear-gradient(to top, var(--popup-gradient));
        }

        &_bottom {
            background: linear-gradient(to bottom, var(--popup-gradient));
        }
    }

    &.popup_type_tooltip:before {
        border-color: var(--color-background-alfa-on-color);
    }

    &.popup_type_tooltip {
        &.popup_direction_top-left:before,
        &.popup_direction_top-center:before,
        &.popup_direction_top-right:before {
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
        }

        &.popup_direction_left-top:before,
        &.popup_direction_left-center:before,
        &.popup_direction_left-bottom:before {
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        &.popup_direction_right-top:before,
        &.popup_direction_right-center:before,
        &.popup_direction_right-bottom:before {
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
        }

        &.popup_direction_bottom-left:before,
        &.popup_direction_bottom-center:before,
        &.popup_direction_bottom-right:before {
            border-top-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
        }
    }
}
