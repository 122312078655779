/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

:root {
    --size-m-box: 16px;
    --size-l-box: 24px;
}

.checkbox {
    display: inline-flex;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
    font-family: var(--font);
    font-weight: var(--font-weight-normal);
    line-height: 1;

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &__control {
        opacity: 0;
        position: absolute;
        margin: 0;
        z-index: -1;
    }

    &__box {
        display: inline-flex;
        position: relative;
        margin-top: 0.05em;
        border: 1px solid;
        border-radius: var(--border-radius-control);
        flex: 0 0 auto;
        transition: all 0.05s 0.1s ease-out;

        &:after {
            content: "";
            display: block;
            position: absolute;
            background: transparent;
            border-radius: 4px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__text {
        line-height: normal;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 2px));
        transition: all 0.1s 0s ease-out;
        opacity: 0;
    }

    &_size_m {
        font-size: var(--font-size-m);

        .checkbox__box,
        .checkbox__box:after {
            width: var(--size-m-box);
            height: var(--size-m-box);
        }

        .checkbox__text {
            padding-left: calc(var(--gap-xs) + 2px);
        }
    }

    &_size_l {
        font-size: var(--font-size-xl);

        .checkbox__box,
        .checkbox__box:after {
            width: var(--size-l-box);
            height: var(--size-l-box);
        }

        .checkbox__icon {
            transform: translate(-50%, calc(-50% - 3px));
        }

        .checkbox__text {
            padding-left: var(--gap-s);
        }
    }

    &_disabled {
        cursor: default;
    }
}

.checkbox_type_button {
    display: inline-block;

    /* TODO remove? */
    &.checkbox_checked {
        .button__text {
            font-weight: var(--font-weight-normal);
        }
    }
}

.checkbox_view_line {
    &.checkbox_size_m {
        margin-right: var(--gap-s);
    }

    &.checkbox_size_l {
        margin-right: var(--gap-m);
    }

    &:last-child {
        margin-right: 0;
    }
}

/* TODO remove? */
.checkbox_invalid {
    .checkbox__box {
        border-color: var(--color-error-translucent);
    }

    &.checkbox_focused,
    &.checkbox_hovered {
        .checkbox__box {
            border-color: var(--color-error-translucent);
        }
    }
}

.checkbox_checked {
    .checkbox__box {
        transition: all 0.05s 0s ease-out;
    }

    .checkbox__icon {
        transition: all 0.1s 0.05s ease-out;
        transform: translate(-50%, -50%);
        opacity: var(--opacity-active);
    }
}

.checkbox_focused {
    .checkbox__box {
        &:after {
            border: 2px solid;
        }
    }
}
