/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.checkbox-group {
    display: inline-block;
    outline: none;
    font-family: var(--font);
    font-weight: var(--font-weight-normal);
}

.checkbox-group__label {
    margin-bottom: var(--gap-xs);
    font-size: var(--font-size-m);
    line-height: var(--line-height-normal);
}

.checkbox-group_invalid {
    border-left: 1px solid var(--color-error-translucent);
    padding-left: 10px;
}

.checkbox_size_m {
    margin-bottom: 15px;
}

.checkbox_size_l {
    margin-bottom: 17px;
}

.checkbox-group_type_button {
    .checkbox-group__box {
        display: flex;
        flex-wrap: wrap;
        margin-right: calc((var(--gap-s) / 2) * -1);
        margin-bottom: calc((var(--gap-s) / 2) * -1);
    }

    .checkbox {
        width: auto;
        flex-grow: 0;
        margin-right: calc(var(--gap-s) / 2);
        margin-bottom: calc(var(--gap-s) / 2);
    }
}

.checkbox-group_width_available {
    display: block;

    .checkbox {
        flex-grow: 1;
    }
}

.checkbox-group_type_line {
    .checkbox {
        margin-bottom: 0;
    }

    .checkbox_size_m {
        margin-right: 13px;
    }

    .checkbox_size_l {
        margin-right: 15px;
    }

    .checkbox:last-child {
        margin-right: 0;
    }
}
